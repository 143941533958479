import { Page } from "components";
import { LoginForm } from "components/LoginForm";
import { Pattern } from "components/Pattern/Pattern";
import { ThemeProvider } from "foundation/Theme";
import { Logo } from "foundation/Logo";
import { homeRoute } from "src/routes";

import styles from "./Login.module.scss";

export default function Login() {
  return (
    <ThemeProvider theme="cream">
      <Page soloPage>
        <div className={styles.Container}>
          <div className={styles.Header}>
            <Logo type="icon" responsive />
          </div>
          <div className={styles.LoginForm}>
            <h1>Sveiki!</h1>
            <LoginForm returnUrl={homeRoute()} backUrl={homeRoute()} />
          </div>
          <div className={styles.Footer}>
            <Pattern />
          </div>
        </div>
      </Page>
    </ThemeProvider>
  );
}
