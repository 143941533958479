import React from "react";
import classNames from "classnames";

import { Themes, themeStyleName } from "styles";

import styles from "./Spinner.module.scss";

interface Props {
  theme?: Themes;
}

export function Spinner({ theme = "cream" }: Props) {
  return <div className={classNames(styles.Spinner, themeStyleName(theme, "Spinner"))} />;
}
