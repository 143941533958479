import { useState } from "react";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import Link from "next/link";
import axios from "axios";

import { loginRoute, registerPageRoute, homeRoute } from "src/routes";
import { ControlledTextField } from "components/ControlledTextField";
import { Button } from "components/Button";
import { Spinner } from "foundation/Spinner";
import { Text } from "foundation/Text";

import styles from "./LoginForm.module.scss";

interface FormValues {
  email: string;
  password: string;
}

interface Props {
  returnUrl?: string;
  backUrl?: string;
}

export function LoginForm({ returnUrl, backUrl }: Props) {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const { control, trigger, handleSubmit } = useForm<FormValues>();

  const handleFormSubmit = async (data: FormValues) => {
    setLoading(true);
    axios
      .post(loginRoute(), data)
      .then((result) => {
        if (result.data.success) {
          router.push(returnUrl || homeRoute());
        }
      })
      .catch(() => {
        setError("Nepavyko prisijungti");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const triggerFormValidation = async (name: keyof FormValues) => {
    return await trigger(name);
  };

  const handleBackClick = () => {
    router.push(backUrl || homeRoute());
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <ControlledTextField
          control={control}
          triggerFormValidation={triggerFormValidation}
          name="email"
          placeholder="El. paštas"
          type="email"
          required
        />

        <ControlledTextField
          control={control}
          triggerFormValidation={triggerFormValidation}
          name="password"
          placeholder="Slaptažodis"
          type="password"
          required
        />
        {error && <div className={styles.Error}>{error}</div>}
        <div className={styles.NoAccount}>
          Neturite paskyros? <Text type="accent">
            <Link href={registerPageRoute()}>Registruotis</Link>
          </Text>
        </div>
        <div className={styles.ButtonContainer}>
          <Button key="submit" responsive minWidth={200} type="submit" priority="primary">
            {!loading ? "Prisijungti" : <Spinner theme="midGreen" />}
          </Button>
          <Button key="back" type="button" responsive minWidth={200} onClick={handleBackClick} priority="secondary">
            Atgal
          </Button>
        </div>
      </form>
    </>
  );
}
