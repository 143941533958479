import React from "react";
import classNames from "classnames";
import { Controller, RegisterOptions, Validate } from "react-hook-form";

import styles from "./ControlledTextField.module.scss";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  control: any;
  validate?: any;
  triggerFormValidation: (name?: any) => Promise<boolean>;
}

export function ControlledTextField({
  control,
  validate,
  triggerFormValidation,
  required,
  type,
  placeholder,
  className,
  name,
  ...rest
}: Props) {
  if (!name) {
    throw new Error("Requires a name prop");
  }

  const rules: RegisterOptions = {};

  if (validate) {
    rules.validate = validate;
  }

  if (required) {
    rules.required = "Šis laukas yra privalomas";
  }

  if (type === "email") {
    rules.pattern = {
      value: /\S+@\S+\.\S+/,
      message: "Neteisingas el. pašto adresas",
    };
  }

  if (type === "postalCode") {
    rules.pattern = {
      value: /^\d{5}$/,
      message: "Neteisingas pašto kodas",
    };
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={rest.defaultValue}
      rules={rules}
      render={({ field: { onChange, value, ...field }, fieldState: { error, isTouched } }) => {
        const labelMarkup = placeholder && (
          <div className={classNames(styles.Label, { [styles.Hidden]: !value })}>
            <label>{placeholder}</label>
          </div>
        );

        const errorMarkup = error && <div className={styles.Error}>{error.message}</div>;
        const hintMarkup = required && !isTouched && <div className={styles.Hint}>* Privalomas laukas</div>;
        return (
          <div className={styles.Container}>
            {labelMarkup}
            <div
              className={classNames(styles.InputContainer, {
                [styles.PostalCode]: type === "postalCode",
                [styles.InputError]: error != null,
                [styles.Disabled]: rest.disabled,
              })}
            >
              <input
                {...field}
                {...rest}
                className={classNames(styles.Input, className)}
                placeholder={placeholder}
                type={type}
                value={value}
                onChange={onChange}
                onBlur={async (event) => {
                  await triggerFormValidation(name);
                  field.onBlur && field.onBlur();
                  rest.onBlur && rest.onBlur(event);
                }}
              />
            </div>
            {errorMarkup}
            {hintMarkup}
          </div>
        );
      }}
    />
  );
}
